















































































































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import { Printd } from 'printd'
import RiskReport from '@/store/entities/financial/riskreport';
import EditTabs from './edit-tabs.vue';
import MultiField from './multi-field.vue';

class PageRiskReportRequest {
    vertical: number = Util.abp.setting.get('DEFAULT_VERTICAL');
    application: number = null;
    customer: number = null;
}

@Component({
    components: { FormBase, VWidget, EditTabs, MultiField },
})

export default class RiskReportForm extends ListBase {
    entity: string = 'riskreport';
    pagerequest: PageRiskReportRequest = new PageRiskReportRequest();
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Boolean, default: true }) showFilters: boolean;
    riskreport: RiskReport = new RiskReport();
    formBase: any = null;
    modalShow: boolean = true;
    filterForm: boolean = true;
    found: boolean = false;
    tabModalShow: boolean = false;
    invoiceHtml: string = '';
    tab: any = null;
    tVert: any = Util.abp.list.getCodeList('T_VERTICAL');
    
    filterItems = [
        this.gItem({ type: 'object', prop: 'customer', label: 'Buscar Cliente por Nombre, RUC, etc.', itemText: 'description', autocomplete: true, size: 4, hideDetails: true, onchange: this.changeCustomer }),
        this.gItem({ type: 'object', prop: 'application', label: 'Solicitud de Evaluacion', size: 4, itemSubTitle: 'subtitle', itemChip: 'code', returnObject: true, loadOptions: false, hideDetails: true, onchange: this.loadData }),
        this.gItem({ type: 'radio', prop: 'vertical', label: 'Vertical', size: 4, hideDetails: true, generalType: 'T_VERTICAL', row: true, disabled: true }),
    ]

    /************* ARRAY ATTRIBUTES ***************/
    get itemsG() {
        let list = [];
        list[1] = [
            this.gItem({ type: 'file', prop: 'logo_upload', label: 'Logo', size: 6, onchange: this.onLogoChange }),
            this.gItem({ type: 'label', prop: 'logo', label: 'Logo', size: 6 }),
            this.gItem({ type: 'text', prop: 'customer_name', label: 'Razon Social', readonly: true, size: 8 }),
            this.gItem({ type: 'text', prop: 'document', label: 'RUC', size: 4, readonly: true }),
            this.gItem({ type: 'date', prop: 'constitution_date', label: 'Fecha de Constitucion', size: 3 }),
            this.gItem({ type: 'select', prop: 'customer_type', label: 'Tipo Cliente', size: 3, generalType: 'T_CUSTOMER' }),
            this.gItem({ type: 'text', prop: 'sunat_dependency', label: 'Dependencia SUNAT', size: 6 }),
            this.gItem({ type: 'text', prop: 'sunat_economic_activity', label: 'Actividad Economica SUNAT', size: 6 }),
            this.gItem({ type: 'text', prop: 'financing_type', label: 'Tipo de Financiamiento', size: 6 }),
            this.gItem({ type: 'text', prop: 'financial_classification', label: 'Clasificacion Sist.Financiero', size: 6 }),
            this.gItem({ type: 'text', prop: 'exercise', label: 'Actividad', size: 6 }),
            this.gItem({ type: 'html', prop: 'shareholders', label: 'Accionistas', size: 12 }),
            this.gItem({ type: 'file', prop: 'shareholders_fileo', label: 'Imagen Accionistas', preicon: 'mdi-camera', size: 8, onchange: this.onShareholdersChange }),
            this.gItem({ type: 'label', prop: 'shareholders_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        list[2] = [
            this.gItem({ type: 'html', prop: 'financing_rating', label: 'Calificacion de la deuda en el sistema financiero', size: 12 }),
            this.gItem({ type: 'file', prop: 'financing_rating_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onFinancingRatingChange }),
            this.gItem({ type: 'label', prop: 'financing_rating_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
            this.gItem({ type: 'html', prop: 'delays_document', label: 'Retrasos y/o documentos impagos', size: 12 }),
            this.gItem({ type: 'file', prop: 'delays_document_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onDelaysDocumentChange }),
            this.gItem({ type: 'label', prop: 'delays_document_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        list[3] = [
            this.gItem({ type: 'html', prop: 'credit_experience', label: 'Experiencia Crediticia Eurorenting', size: 12 }),
            this.gItem({ type: 'file', prop: 'credit_experience_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onCreditExperienceChange }),
            this.gItem({ type: 'label', prop: 'credit_experience_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        return list;
    } 

    get itemsF() {
        let list = [];
        list[4] = [
            this.gItem({ type: 'html', prop: 'current_fleet', label: 'Flota Vigente', size: 12 }),
            this.gItem({ type: 'file', prop: 'current_fleet_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onCurrentFleetChange }),
            this.gItem({ type: 'label', prop: 'current_fleet_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        list[5] = [
            this.gItem({ type: 'switch', prop: 'show_proposals', label: 'Mostrar Detalle de Propuesta', size: 12 }),
        ];

        return list;
    } 

    get itemsT() {
        let list = [];
        list[4] = [
            this.gItem({ type: 'switch', prop: 'show_proposals', label: 'Mostrar Detalle de Propuesta', size: 12 }),
        ];

        list[6] = [
            this.gItem({ type: 'html', prop: 'obligations', label: 'Generacion de ingresos Vs Obligaciones Financieras Mensuales', size: 12 }),
            this.gItem({ type: 'file', prop: 'obligations_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onObligationsChange }),
            this.gItem({ type: 'label', prop: 'obligations_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        list[7] = [
            this.gItem({ type: 'html', prop: 'conditions', label: 'Condiciones', size: 12 }),
            this.gItem({ type: 'file', prop: 'conditions_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onConditionsChange }),
            this.gItem({ type: 'label', prop: 'conditions_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        list[8] = [
            this.gItem({ type: 'html', prop: 'annexes', label: 'Anexos', size: 12 }),
            this.gItem({ type: 'file', prop: 'annexes_fileo', label: 'Imagen', preicon: 'mdi-camera', size: 8, onchange: this.onAnnexesChange }),
            this.gItem({ type: 'label', prop: 'annexes_file', size: 4, close: true, onclickClose: this.onDeleteImage }),
        ];

        return list;
    } 

    columns42 = [
        { value: 'number', text: '#', sortable: false },
        { value: 'proposal_type', text: 'Tipo', sortable: false, width: '130px', class: 'px-1' },
        { value: 'model', text: 'Marca / Modelo', sortable: false, class: 'px-1' },
        { value: 'quantity', text: 'Cant', sortable: false, class: 'px-1' },
        { value: 'total', text: 'Monto Total', sortable: false, class: 'px-1' },
        { value: 'initial', text: 'Ini', sortable: false, class: 'px-1' },
        { value: 'total_investment', text: 'Monto Financ.', sortable: false, class: 'px-1' },
        { value: 'total_monthly', text: 'Cuota mensual', sortable: false, class: 'px-1' },
        { value: 'cuotes', text: 'Letra', sortable: false, class: 'px-1' },
        { value: 'tea', text: 'Tasa Interes Anual', sortable: false, class: 'px-1' },
        { value: 'warranty', text: 'Garantia Anual', sortable: false, class: 'px-1' },
        { value: 'days_stock', text: 'Dias Stock', sortable: false, class: 'px-1' },
        { value: 'net_margin', text: 'Margen', sortable: false, class: 'px-1' },
        { value: 'action', sortable: false, class: 'px-1' }
    ]
    
    columns5 = [
        { value: 'number', text: '#', sortable: false, class: 'px-1' },
        { value: 'proposal_type', text: 'Tipo', sortable: false, class: 'px-1', width: '100px' },
        { value: 'model', text: 'Marca modelo', sortable: false, class: 'px-1' },
        { value: 'quantity', text: 'Cant.', sortable: false, class: 'px-1' },
        { value: 'months', text: 'Plazo (meses)', sortable: false, class: 'px-1' },
        { value: 'tea', text: 'TEA', sortable: false, class: 'px-1' },
        { value: 'total_investment', text: 'Inversion total', sortable: false, class: 'px-1' },
        { value: 'total_monthly', text: 'Tarifa mensual total', sortable: false, class: 'px-1' },
        { value: 'factor', text: 'Factor', sortable: false, class: 'px-1' },
        { value: 'van', text: 'VAN ECON', sortable: false, class: 'px-1' },
        { value: 'tir', text: 'TIR ECO', sortable: false, class: 'px-1' },
        { value: 'gross_margin', text: 'Margen bruto', sortable: false, class: 'px-1' },
        { value: 'net_margin', text: 'Margen neto', sortable: false, class: 'px-1' },
        { value: 'profitability', text: 'Rentabilidad', sortable: false, class: 'px-1' },
        { value: 'vr', text: 'VR', sortable: false, class: 'px-1' },
        { value: 'cuotes', text: 'Nuevo plazo', sortable: false, class: 'px-1' },
        { value: 'action', sortable: false, class: 'px-1' }
    ]

    onLogoChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['logo_data'] = (e as any).target.result;
                vm.riskreport.logo = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onShareholdersChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['shareholders_filedata'] = (e as any).target.result;
                vm.riskreport.shareholders_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onFinancingRatingChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['financing_rating_filedata'] = (e as any).target.result;
                vm.riskreport.financing_rating_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onDelaysDocumentChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['delays_document_filedata'] = (e as any).target.result;
                vm.riskreport.delays_document_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onCreditExperienceChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['credit_experience_filedata'] = (e as any).target.result;
                vm.riskreport.credit_experience_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onCurrentFleetChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['current_fleet_filedata'] = (e as any).target.result;
                vm.riskreport.current_fleet_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onStatementChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['statement_filedata'] = (e as any).target.result;
                vm.riskreport.statement_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onStatement2Change(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['statement2_filedata'] = (e as any).target.result;
                vm.riskreport.statement2_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onStatement3Change(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['statement3_filedata'] = (e as any).target.result;
                vm.riskreport.statement3_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onIndicatorsChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['indicators_filedata'] = (e as any).target.result;
                vm.riskreport.indicators_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onIndicators2Change(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['indicators2_filedata'] = (e as any).target.result;
                vm.riskreport.indicators2_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onIndicators3Change(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['indicators3_filedata'] = (e as any).target.result;
                vm.riskreport.indicators3_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onObligationsChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['obligations_filedata'] = (e as any).target.result;
                vm.riskreport.obligations_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onConditionsChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['conditions_filedata'] = (e as any).target.result;
                vm.riskreport.conditions_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    onAnnexesChange(file) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.riskreport['annexes_filedata'] = (e as any).target.result;
                vm.riskreport.annexes_file = file.name;
            };
            reader.readAsDataURL(file);
        }
    }

    async onDeleteImage(item) {
        if (this.riskreport == undefined) return;
        await this.$store.dispatch({
            type: 'riskreport/deleteImage',
            data: {
                id: this.riskreport.id,
                field: item.prop
            }
        });

        this.riskreport[item.prop] = null;
        this.riskreport[item.prop + 'o'] = null;
    }

    addProposal(item) {
        if (item.proposal_type != null && item.proposal_type != '') {
            this.$store.commit('riskreport/addProposal');
        }
    }

    deleteProposal(index) {
        this.$store.commit('riskreport/deleteProposal', index);
    }

    showTab(id) {
        if (this.riskreport == null) return false;
        if (this.riskreport.tabs == null) return false;
        return this.riskreport.tabs.includes(id);
    }

    changeTab(idx) {
    }

    /************* COMPUTED METODOS ***************/
    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get isEditing() {
        return this.$store.state[this.entity].editModel.id > 0;
    }
    
    get isRisk() {
        return Util.abp.session.isRisk();
    }

    get isRiskManager() {
        return Util.abp.session.isRiskManager();
    }

    get isReviewed() {
        if (this.$store.state.application.editModel == null) return false;
        if (this.$store.state.application.editModel.reviewed == undefined) return false;
        return this.$store.state.application.editModel.reviewed;
    }

    get proposals() {
        return this.$store.state.riskreport.editModel.proposals;
    }

    get disabledForm() {
        return !this.found || (!this.isRisk && !this.isRiskManager) || this.isReviewed; 
    }

    get totals() {
        let tot: any = {
            quantity: 0,
            total: 0,
            initial: 0,
            total_investment: 0,
            total_monthly: 0
        };
        
        this.proposals.forEach(m => {
            if (m.quantity != null && m.quantity > 0) tot.quantity += parseFloat(m.quantity);
            if (m.total != null && m.total > 0) tot.total += parseFloat(m.total);
            if (m.initial != null && m.initial > 0) tot.initial += parseFloat(m.initial);
            if (m.total_investment != null && m.total_investment > 0) tot.total_investment += parseFloat(m.total_investment);
            if (m.total_monthly != null && m.total_monthly > 0) tot.total_monthly += parseFloat(m.total_monthly);
        });

        return tot;
    }

    get tabs() {
        if (this.riskreport == null) return [];
        if (this.riskreport.tabs == null) return [];
        let vertical = Util.abp.list.getItem(this.pagerequest.vertical);
        let items = Util.abp.list.getList('RISK_V' + vertical.code);
        let list = [];

        items.forEach(a => {
            if (this.riskreport.tabs.includes(a.value)) {
                list.push({ id: a.value, name: a.name });
            }
        });

        return list;
    }

    /************* METODOS DE INTERFACE ***************/
    async changeCustomer(customer) {
        this.$store.commit('application/setActiveList', []);
        let state = Util.abp.list.get('E_APPLY','CO');
        await this.$store.dispatch({
            type: 'application/getAllActive',
            data: {
                state: state.id,
                customer: customer,
            }
        });
    }

    async loadData(data) {

        if (data != null) {
            this.pagerequest.vertical = data.vertical_id;
            let model = await this.$store.dispatch({
                type: 'riskreport/getRisk',
                data: {
                    customer: this.pagerequest.customer,
                    vertical: this.pagerequest.vertical,
                    application: data.id
                }
            });
            this.found = true;
            this.$store.commit('riskreport/edit', model);
            this.riskreport = Util.extend(true, {}, this.$store.state.riskreport.editModel);
            
            if (!this.found || (!this.isRisk && !this.isRiskManager) || this.isReviewed) {
            }

        } else {
            this.initForm();
        }

    }

    afterSaveTabs(model) { 
        this.riskreport.tabs = model.tabs;
    }

    /************* METODOS DE INICIALIZACION ***************/
    async initForm() {
        this.found = false;
        this.$store.commit('riskreport/new');
        this.riskreport = Util.extend(true, {}, this.$store.state.riskreport.editModel);
    }

    /************* BOTONES DE ACCION ***************/        
    async save() {
        this.riskreport.proposals = this.proposals;
        this.riskreport.fields = this.$store.state.riskreport.editModel.fields;

        let newModel = await this.$store.dispatch({
            type: this.entity + '/' + (this.riskreport.id > 0 ? 'update' : 'create'),
            data: this.riskreport
        });

        this.$store.commit('riskreport/edit', newModel);
        this.riskreport = Util.extend(true, {}, this.$store.state.riskreport.editModel);
        this.$swal('Completado', 'El Informe de Riesgo ha sido actualizado', 'success');
    }

    async download() {
        let url = await this.$store.dispatch({
            type: 'riskreport/download',
            data: {
                id: this.riskreport.id,
                filename: 'INFORME-RIESGO-' + this.riskreport.id + '-' + this.riskreport['document'] + '.pdf'
            }
        });

        //window.location = url;

        //if (html != '') {
        //    this.invoiceHtml = html;

        //    setTimeout(() => {
        //        const d = new Printd()
        //        d.print(document.getElementById('LprintHTML'));
        //        this.invoiceHtml = '';
        //    }, 500);
        //}
    }

    async mounted() {
        this.found = false;
        this.initForm();
    }

    async created() {
        this.found = false;
    }
}
