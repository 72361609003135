






































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';


@Component({
    components: { FormBase }, 
})
export default class MultiField extends ListBase {
    entity: string = 'application'
    @Prop({ type: Number, default: 1 }) fieldValue: number;
    @Prop({ type: Boolean, default: false }) disabled: boolean;
    @Prop({ type: Object, default: () => { return { id: null, name: '' } } }) model: object;
    valid: boolean = true;
    optionsHtml: any = {
        hideModules: { "link": true, "code": true, "image": true, "table": true },
        maxHeight: "250px",
        forcePlainTextOnPaste: true,
    };

    /************* COMPUTED LIST ***************/
    get riskreport() {
        return this.$store.state.riskreport.editModel;
    }
    get list() {
        return this.$store.state.riskreport.editModel.fields.filter(i => {
            return i.field_id == this.field.id;
        });
    }
    get length() {
        return this.list.length;
    }
    get loading() {
        return this.$store.state.riskreport.loading;
    }
    get field() {
        return Util.abp.list.getByValue('RISK_FIELDS', this.fieldValue);
    }
    


    /************* BOTONES DE ACCION ***************/
    addField() {
        this.$store.commit('riskreport/addField', {
            risk_report_id: this.riskreport.id,
            field_id: this.field.id,
            filename: null,
            content: '',
            number: this.length + 1,
        });
    }
    removeField() {
        this.$store.commit('riskreport/removeField', this.list[this.length - 1]);
    }
    onFileChange(file, item) {
        if (file != null) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                item['filedata'] = (e as any).target.result;
                item.filename = file.name;
            };
            reader.readAsDataURL(file);
        }
    }
    async onDeleteImage(item) {
        await this.$store.dispatch({
            type: 'riskreport/deleteFieldImage',
            data: item
        });

        item.filename = null;
    }

    getItem(id) {
        return Util.abp.list.getItem(id);
    }
    onFocus(e) {
        e.target.select();
    }

    /********* EVENTOS DE RETORNO DE MODALES ********/


    /************* EVENTOS DE FILA ***************/
    propValue(data: any, field: string) {
        let value = Util.getEntityValue(data, field);
        return value;
    }
    updateDetail(item) {
        //item.quantity = parseFloat(String(item.quantity));
        //item.day_fee = parseFloat(String(item.day_fee));
        //item.price = parseFloat(String(item.price));
        //item.equipment = parseFloat(String(item.equipment));
        //item.month_fee = (item.day_fee * 365) / 12;
        //item.total = item.price + item.equipment; 
    }

    created() {

    }

    mounted() {

    }
}
