








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import ListBase from '@/lib/listbase';
import RiskReport from '@/store/entities/financial/riskreport';

@Component({
    components: { ModalView, FormBase },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.initForm();
            } else
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    },
})

export default class EditTabs extends ListBase {
    name: string = 'edit-tabs';
    entity: string = 'riskreport';
    @Prop({ type: Boolean, default: false }) value: boolean;
    riskreport: RiskReport = new RiskReport();
    modalSetting: any = {
        entity: "riskreport",
        titleFull: 'Editar Estructura de Informe de Riesgos',
        showForm: false,
        width: '600px'
    };
    
    /************* ARRAY ATTRIBUTES ***************/
    items = [
        this.gItem({ type: 'object', prop: 'tabs', label: 'Seleccione los Items que desea Utilizar', size: 12, options: [], multiple: true, clearable: false }),
    ];


    /************* COMPUTED METODOS ***************/
    get loading() {
        return this.$store.state[this.entity].loading;
    }

    get application() {
        return this.$store.state.application.editModel;
    }

    /************* METODOS DE INICIALIZACION ***************/
    initForm() {
        this.riskreport = Util.extend(true, {}, this.$store.state.riskreport.editModel);
        let vertical = Util.abp.list.getItem(this.riskreport.vertical_id);
        let items = Util.abp.list.getList('RISK_V' + vertical.code).map(a => {
            return { id: a.value, name: a.value + '. ' + a.name };
        });
        this.setItem(this.items, 'tabs', 'options', items)
    
    }

    /************* METODOS DE CALCULO ***************/


    /************* BOTONES DE ACCION ***************/
    async save() {
        if ((this.$refs.entityForm as any).validate()) {
            
            let newModel = await this.$store.dispatch({
                type: 'riskreport/updateTabs',
                data: {
                    id: this.riskreport.id,
                    tabs: this.riskreport.tabs
                }
            });

            this.$emit('save-success', newModel);
            this.cancel();
        }
        return false;
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {

    }

    created() {
    }
}
